import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In honor of the U.S. Treasury’s March shift to paperless payments for Social Security benefits recipients, The Center for American Progress hosted a thoughtful discussion on the DirectExpress card and how prepaid cards in general can be a financial capability tool. Special thanks to Melissa Koide, Deputy Assistant Secretary for Consumer Policy, U.S. Department of Treasury, for highlighting the role that PayPerks can play toward that end! (tune in around minute 22 to hear what she had to say about PayPerks)`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.americanprogress.org/events/2013/02/22/54461/the-end-of-cash/"
      }}>{`http://www.americanprogress.org/events/2013/02/22/54461/the-end-of-cash/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      